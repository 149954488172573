import React from 'react';
import PropTypes from 'prop-types';
import './SellerCertificationPopup.css';

/**
 * 모범셀러 인증 안내 팝업 컴포넌트
 */
const SellerCertificationPopup = ({ onClose }) => {
  return (
    <div className="seller-certification-popup-overlay">
      <div className="seller-certification-popup">
        <div className="popup-header">
          <h3>해당 기능은 후루츠 모범셀러만 사용이 가능합니다.</h3>
          <button className="close-button" onClick={onClose}>×</button>
        </div>
        <div className="popup-content">
          <h4>모범셀러란?</h4>
          <p>
            아래 2가지 조건을 달성했을 때 부여되는 배지로<br />
            후루츠가 인증한 &apos;믿고 거래할 수 있는 셀러&apos;입니다.
          </p>
          <div className="certification-requirements">
            <div className="requirement">
              <div className="requirement-icon">1</div>
              <div className="requirement-text">
                안전 결제를 통한 &apos;판매 완료&apos; 상태의 상품이 20개 이상<br />
                (개인 간 계좌 거래는 제외)
              </div>
            </div>
            <div className="requirement">
              <div className="requirement-icon">2</div>
              <div className="requirement-text">
                Shop 리뷰 평점이 평균 4.5점 이상
              </div>
            </div>
          </div>
        </div>
        <div className="popup-footer">
          <button className="confirm-button" onClick={onClose}>확인</button>
        </div>
      </div>
    </div>
  );
};

SellerCertificationPopup.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default SellerCertificationPopup;
