import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import SuggestionList from './SuggestionList';
import './ProductBasicInfo.css';
import {useLazyQuery} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import { debounce } from 'lodash';

// GraphQL 쿼리 정의
const GET_SUGGEST_BRANDS = gql`
  query getSuggestBrandsByTitle($title: String!) {
    getSuggestBrandsByTitle(title: $title) {
      id
      name
    }
  }
`;

const GET_SUGGEST_CATEGORIES = gql`
  query getSuggestCategoriesByTitle($title: String!, $brand: String) {
    getSuggestCategoriesByTitle(title: $title, brand: $brand) {
      gender
      category
      subcategory
      subcategory_id
    }
  }
`;

// 최대 입력 글자 수 상수 정의
const MAX_TITLE_LENGTH = 40;
const MAX_DESCRIPTION_LENGTH = 2500;

const ProductBasicInfo = ({ register, watch, errors, trigger, setValue }) => {
    const title = watch('title') || '';
    const description = watch('description') || '';
    const brandFromForm = watch('brand') || '';
    
    // 추천 상태 관리
    const [suggestedBrands, setSuggestedBrands] = useState([]);
    const [suggestedCategories, setSuggestedCategories] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [selectedBrandIndex, setSelectedBrandIndex] = useState(-1);
    const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(-1);
    
    // GraphQL 쿼리 훅
    const [getBrands, { data: brandsData, loading: brandsLoading }] = useLazyQuery(GET_SUGGEST_BRANDS);
    const [getCategories, { data: categoriesData, loading: categoriesLoading }] = useLazyQuery(GET_SUGGEST_CATEGORIES);

    // 브랜드 및 카테고리 데이터 업데이트
    useEffect(() => {
        if (brandsData && brandsData.getSuggestBrandsByTitle) {
            setSuggestedBrands(brandsData.getSuggestBrandsByTitle);
            setSelectedBrandIndex(-1); // 새로운 추천 목록이 로드되면 선택 초기화
        }
    }, [brandsData]);

    useEffect(() => {
        if (categoriesData && categoriesData.getSuggestCategoriesByTitle) {
            setSuggestedCategories(categoriesData.getSuggestCategoriesByTitle);
            setSelectedCategoryIndex(-1); // 새로운 추천 목록이 로드되면 선택 초기화
        }
    }, [categoriesData]);

    // 디바운스된 쿼리 함수
    const debouncedSearch = useCallback(
        debounce((searchTerm) => {
            if (searchTerm.length >= 2) {
                getBrands({ variables: { title: searchTerm } });
                getCategories({ 
                    variables: { 
                        title: searchTerm,
                        brand: brandFromForm || null
                    } 
                });
                setShowSuggestions(true);
            } else {
                setShowSuggestions(false);
            }
        }, 500),
        [brandFromForm]
    );

    // 타이틀 입력 시 실시간 유효성 검사 및 추천 검색
    const handleTitleChange = async () => {
        await trigger('title');
        const currentTitle = watch('title') || '';
        debouncedSearch(currentTitle);
    };

    // 설명 입력 시 실시간 유효성 검사
    const handleDescriptionChange = async () => {
        await trigger('description');
    };

    // 추천 항목 선택 핸들러
    const handleSelectBrand = (brand, index) => {
        // 이미 선택된 브랜드를 다시 클릭한 경우 선택 취소
        if (selectedBrandIndex === index) {
            setValue('brand', '');
            setSelectedBrandIndex(-1);
            
            // 브랜드가 변경되었으므로 카테고리 추천 다시 요청
            const currentTitle = watch('title') || '';
            if (currentTitle.length >= 2) {
                getCategories({ 
                    variables: { 
                        title: currentTitle,
                        brand: null
                    } 
                });
            }
        } else {
            // 브랜드 객체 전체를 저장하지 않고 이름만 저장
            setValue('brand', brand.name);
            // 선택된 브랜드 인덱스 업데이트
            setSelectedBrandIndex(index);
            
            // 브랜드가 변경되었으므로 카테고리 추천 다시 요청
            const currentTitle = watch('title') || '';
            if (currentTitle.length >= 2) {
                getCategories({ 
                    variables: { 
                        title: currentTitle,
                        brand: brand.name
                    } 
                });
            }
        }
        // 추천 목록은 계속 표시
    };

    const handleSelectCategory = (category, index) => {
        // 이미 선택된 카테고리를 다시 클릭한 경우 선택 취소
        if (selectedCategoryIndex === index) {
            setValue('gender', '');
            setValue('category', '');
            setValue('subCategory', '');
            setSelectedCategoryIndex(-1);
        } else {
            // 성별 설정
            setValue('gender', category.gender === 'men' ? '남성' : 
                            category.gender === 'women' ? '여성' : '공용');
            
            // 카테고리 설정
            setValue('category', category.category);
            
            // 서브카테고리 설정
            setValue('subCategory', category.subcategory);
            
            // 선택된 카테고리 인덱스 업데이트
            setSelectedCategoryIndex(index);
        }
        // 추천 목록은 계속 표시
    };

    return (
        <div className="ProductBasicInfo-container">
            <div className="ProductBasicInfo-inputContainer">
                <div className="ProductBasicInfo-inputHeader">
                    <label htmlFor="title">제목</label>
                    <span className={`ProductBasicInfo-charCount ${title.length > MAX_TITLE_LENGTH ? 'ProductBasicInfo-charCount--exceeded' : ''}`}>
                        {title.length} / {MAX_TITLE_LENGTH}
                    </span>
                </div>
                <input
                    id="title"
                    type="text"
                    className={`ProductBasicInfo-formInput ${title.length > MAX_TITLE_LENGTH ? 'ProductBasicInfo-formInput--error' : ''}`}
                    placeholder="상품 제목을 입력해주세요"
                    {...register('title', {
                        required: '제목을 입력해주세요',
                        maxLength: {
                            value: MAX_TITLE_LENGTH,
                            message: `최대 ${MAX_TITLE_LENGTH}자까지 입력 가능합니다`
                        },
                        onChange: handleTitleChange
                    })}
                />
                {errors.title && <p className="ProductBasicInfo-errorMessage">{errors.title.message}</p>}
            </div>
            
            {/* SuggestionList 컴포넌트 - className은 SuggestionList 내부에서 관리 */}
            <SuggestionList 
                showSuggestions={showSuggestions}
                title={title}
                suggestedBrands={suggestedBrands}
                suggestedCategories={suggestedCategories}
                selectedBrandIndex={selectedBrandIndex}
                selectedCategoryIndex={selectedCategoryIndex}
                brandsLoading={brandsLoading}
                categoriesLoading={categoriesLoading}
                onSelectBrand={handleSelectBrand}
                onSelectCategory={handleSelectCategory}
            />
            
            <div className="ProductBasicInfo-inputContainer">
                <div className="ProductBasicInfo-inputHeader">
                    <label htmlFor="description">상품설명</label>
                    <span className={`ProductBasicInfo-charCount ${description.length > MAX_DESCRIPTION_LENGTH ? 'ProductBasicInfo-charCount--exceeded' : ''}`}>
                        {description.length} / {MAX_DESCRIPTION_LENGTH}
                    </span>
                </div>
                <textarea
                    id="description"
                    className={`ProductBasicInfo-formTextarea ${description.length > MAX_DESCRIPTION_LENGTH ? 'ProductBasicInfo-formTextarea--error' : ''}`}
                    placeholder="상품 설명을 입력해주세요 (최소 10자)"
                    rows="10"
                    {...register('description', {
                        required: '상품 설명을 입력해주세요',
                        minLength: {
                            value: 10,
                            message: '최소 10자 이상 입력해주세요'
                        },
                        maxLength: {
                            value: MAX_DESCRIPTION_LENGTH,
                            message: `최대 ${MAX_DESCRIPTION_LENGTH}자까지 입력 가능합니다`
                        },
                        onChange: handleDescriptionChange
                    })}
                />
                {errors.description && <p className="ProductBasicInfo-errorMessage">{errors.description.message}</p>}
            </div>
        </div>
    );
};

ProductBasicInfo.propTypes = {
    register: PropTypes.func.isRequired,
    watch: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    trigger: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired
};

export default ProductBasicInfo;
