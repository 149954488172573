import {CATEGORY_ALL, DISCOVER_TAB} from '../page/discover/Discover';
import {SELLER_TAB} from '../page/seller/Seller';

import {seoURIComponent} from './text';

export function webappURL(hostname) {

    let uri;
    switch (hostname) {
        case 'localhost':
            uri = 'http://localhost:3000';
            break;
        case 'staging.fruitsfamily.com':
            uri = 'https://staging.fruitsfamily.com';
            break;
        case 'production.fruitsfamily.com':
        case 'fruitsfamily.com':
        case 'www.fruitsfamily.com':
            uri = 'https://fruitsfamily.com';
            break;
        default:
            uri = 'https://fruitsfamily.com';
            break;
    }
    return uri;
}

export function brandUrl(brandName, category = CATEGORY_ALL) {
    return [
        '/brand',
        encodeURIComponent(brandName),
        category === CATEGORY_ALL ?
            null :
            encodeURIComponent(category.name)
    ].filter(component => component).join('/');
}

export function collectionUrl(collection) {
    const collectionId = parseInt(collection.id, 10);

    return [
        '/collection',
        encodeURIComponent(collectionId.toString(36)),
        seoURIComponent(collection.title)
    ].filter(component => component).join('/');
}

export function discoverUrl(discoverTab = DISCOVER_TAB.PRODUCT, category = CATEGORY_ALL) {
    return [
        '/discover',
        discoverTab === DISCOVER_TAB.PRODUCT && category === CATEGORY_ALL ?
            null :
            seoURIComponent(discoverTab.description),
        discoverTab === DISCOVER_TAB.SHOP || category === CATEGORY_ALL ?
            null :
            encodeURIComponent(category.name)
    ].filter(component => component).join('/');
}

export function downloadUrl() {
    return '/download';
}

export function homeUrl() {
    return '/';
}

export function productUrl(product) {
    const productId = parseInt(product.id, 10);

    return `/product/${encodeURIComponent(productId.toString(36))}/${seoURIComponent(product.title)}`;
}

export function sellerUrl(seller, sellerTab = SELLER_TAB.SHOP) {
    const sellerId = parseInt(seller.id, 10);
    const userName = seller.user !== undefined ? seller.user.username : seller.username

    return [
        '/seller',
        encodeURIComponent(sellerId.toString(36)),
        seoURIComponent(userName),
        sellerTab === SELLER_TAB.SHOP ?
            null :
            seoURIComponent(sellerTab.description)
    ].filter(component => component).join('/');
}

export function searchUrl(keyword, category = CATEGORY_ALL) {
    return [
        '/search',
        encodeURIComponent(keyword),
        category === CATEGORY_ALL ?
            null :
            encodeURIComponent(category.name)
    ].filter(component => component).join('/');
}

export function signUpUrl() {
    return '/signUp';
}

export function signUpSns(snsType, snsToken) {
    return '/signUpSns?snsType=' + snsType + '&token=' + snsToken;
}

export function loginUrl() {
    return '/login';
}

export function myPageUrl(sellerTab = SELLER_TAB.SHOP) {
    return  [
        '/my',
        sellerTab === SELLER_TAB.SHOP ?
            null :
            seoURIComponent(sellerTab.description)
    ].filter(component => component).join('/');
}

export function uploadUrl() {
    return '/upload';
}

export function checkoutUrl(product) {
    const productId = parseInt(product.id, 10);

    return `/checkout/${encodeURIComponent(productId.toString(36))}`;
}

export function friendInviteUrl(code) {
    return `/event/join/${code}`;
}
