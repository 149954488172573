import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './SuggestionList.css';

const SuggestionList = ({
    showSuggestions,
    title,
    suggestedBrands,
    suggestedCategories,
    selectedBrandIndex,
    selectedCategoryIndex,
    brandsLoading,
    categoriesLoading,
    onSelectBrand,
    onSelectCategory,
    brandResults = [],
    brandSearchLoading = false,
    onSelectBrandFromSearch,
    displayType = 'default', // 'default' 또는 'radio'
    showBrandSearchResults = false
}) => {
    const [checkedBrandIndex, setCheckedBrandIndex] = useState(selectedBrandIndex);
    const [checkedCategoryIndex, setCheckedCategoryIndex] = useState(selectedCategoryIndex);

    // 브랜드 검색 결과 표시 (ProductDetailInfo에서 사용)
    if (showBrandSearchResults) {
        // 브랜드 검색 로딩 중
        if (brandSearchLoading) {
            return (
                <div className="loading-indicator">
                    <div className="loading-spinner"></div>
                </div>
            );
        }

        // 브랜드 검색 결과가 없는 경우
        if (brandResults.length === 0) {
            return <div className="placeholder-message">브랜드를 검색하세요</div>;
        }

        // 브랜드 검색 결과 표시
        return (
            <div className="brand-results-container">
                {displayType === 'radio' ? (
                    <div className="common-grid">
                        {brandResults.map((brand, index) => (
                            <label key={brand.id} className="common-option">
                                <input
                                    type="radio"
                                    name="brand"
                                    checked={checkedBrandIndex === index}
                                    onChange={() => {
                                        setCheckedBrandIndex(index);
                                        onSelectBrandFromSearch(brand, index);
                                    }}
                                />
                                <span className={`common-label ${checkedBrandIndex === index ? 'selected' : ''}`}>
                                    {brand.name}
                                </span>
                            </label>
                        ))}
                    </div>
                ) : (
                    <div className="suggestion-section">
                        <h4>브랜드 검색 결과</h4>
                        <div className="common-grid">
                            {brandResults.map((brand, index) => (
                                <label 
                                    key={`brand-search-${index}`} 
                                    className="common-option"
                                    onClick={() => {
                                        setCheckedBrandIndex(index);
                                        onSelectBrandFromSearch(brand, index);
                                    }}
                                >
                                    <input
                                        type="radio"
                                        checked={checkedBrandIndex === index}
                                        readOnly
                                    />
                                    <span className={`common-label ${checkedBrandIndex === index ? 'selected' : ''}`}>
                                        {brand.name}
                                    </span>
                                </label>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        );
    }

    if (!showSuggestions || (title && title.length < 2)) {
        return null;
    }

    // 제목 입력에 따른 추천 리스트 표시
    return (
        <div className="SuggestionList-container">
            <div className="SuggestionList-title">
                이런거 찾으시나요?
            </div>
            {suggestedBrands && suggestedBrands.length > 0 && (
                <div className="SuggestionList-section">
                    <div className="label">브랜드 추천</div>
                    <div className="common-grid">
                        {suggestedBrands.map((brand, index) => (
                            <label 
                                key={`brand-${index}`} 
                                className="common-option"
                                onClick={() => {
                                    setCheckedBrandIndex(index);
                                    onSelectBrand(brand, index);
                                }}
                            >
                                <input
                                    type="radio"
                                    checked={checkedBrandIndex === index}
                                    readOnly
                                />
                                <span className={`common-label ${checkedBrandIndex === index ? 'selected' : ''}`}>
                                    {brand.name}
                                </span>
                            </label>
                        ))}
                    </div>
                </div>
            )}
            
            {/* 카테고리 추천 */}
            {suggestedCategories && suggestedCategories.length > 0 && (
                <div className="SuggestionList-section">
                    <div className="label">카테고리 추천</div>
                    <div className="common-grid">
                        {suggestedCategories.map((category, index) => (
                            <label 
                                key={`category-${index}`} 
                                className="common-option"
                                onClick={() => {
                                    setCheckedCategoryIndex(index);
                                    onSelectCategory(category, index);
                                }}
                            >
                                <input
                                    type="radio"
                                    checked={checkedCategoryIndex === index}
                                    readOnly
                                />
                                <span className={`common-label ${checkedCategoryIndex === index ? 'selected' : ''}`}>
                                    {category.gender === 'men' ? '남성' : 
                                        category.gender === 'women' ? '여성' : '공용'} &gt; {category.category} &gt; {category.subcategory}
                                </span>
                            </label>
                        ))}
                    </div>
                </div>
            )}
            
            {/* 로딩 상태 표시 */}
            {(brandsLoading || categoriesLoading) && (
                <div className="loading-indicator">
                    <div className="loading-spinner"></div>
                </div>
            )}
            
            {/* 결과 없음 표시 */}
            {!brandsLoading && !categoriesLoading && 
             (!suggestedBrands || suggestedBrands.length === 0) && 
             (!suggestedCategories || suggestedCategories.length === 0) && (
                <div className="no-suggestions">추천 결과가 없습니다</div>
            )}
        </div>
    );
};

SuggestionList.propTypes = {
    showSuggestions: PropTypes.bool,
    title: PropTypes.string,
    suggestedBrands: PropTypes.array,
    suggestedCategories: PropTypes.array,
    selectedBrandIndex: PropTypes.number,
    selectedCategoryIndex: PropTypes.number,
    brandsLoading: PropTypes.bool,
    categoriesLoading: PropTypes.bool,
    onSelectBrand: PropTypes.func,
    onSelectCategory: PropTypes.func,
    brandResults: PropTypes.array,
    brandSearchLoading: PropTypes.bool,
    onSelectBrandFromSearch: PropTypes.func,
    displayType: PropTypes.string,
    showBrandSearchResults: PropTypes.bool
};

export default SuggestionList;
