import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './ImageUpload.css';

/**
 * ImageUpload 컴포넌트
 * 이 컴포넌트는 이미지 파일 업로드, 미리보기, 드래그 앤 드롭, 순서 변경 기능을 제공합니다.
 * 최대 10개까지 이미지를 업로드할 수 있으며, 드래그 앤 드롭으로 이미지 순서를 변경할 수 있습니다.
 */
const ImageUpload = ({ onImagesChange }) => {
    const [images, setImages] = useState([]);
    const [isDragging, setIsDragging] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false); // 모달 상태 추가
    const [selectedImage, setSelectedImage] = useState(null); // 선택된 이미지 URL 상태 추가

    useEffect(() => {
        return () => {
            images.forEach(image => {
                if (image.preview && image.preview.startsWith('blob:')) {
                    URL.revokeObjectURL(image.preview);
                }
            });
        };
    }, [images]);

    // 이미지 업데이트 함수를 useCallback으로 메모이제이션
    const updateImages = useCallback((newImages) => {
        setImages(newImages);
        const imageFiles = newImages.map(img => img.file);
        onImagesChange(imageFiles);
    }, [onImagesChange]);

    // 파일 처리 함수를 먼저 정의
    const addNewFiles = useCallback((files) => {
        const imageFiles = files.filter(file => file.type.startsWith('image/'));
        
        if (imageFiles.length === 0) {
            alert('이미지 파일만 업로드할 수 있습니다.');
            return;
        }
        
        // 이미지 파일을 데이터 URL로 변환
        const promises = imageFiles.map(file => {
            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.onload = (e) => {
                    resolve({
                        id: `image-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
                        file,
                        preview: e.target.result // 데이터 URL
                    });
                };
                reader.readAsDataURL(file);
            });
        });
        
        Promise.all(promises).then(newImages => {
            if (images.length + newImages.length <= 10) {
                updateImages([...images, ...newImages]);
            } else {
                alert('이미지는 최대 10개까지만 업로드할 수 있습니다.');
            }
        });
    }, [images, updateImages]);

    // 파일 선택 함수는 addNewFiles 함수 정의 후에 선언
    const handleFileSelect = useCallback((event) => {
        const files = Array.from(event.target.files);
        addNewFiles(files);
        // 파일 선택 후 input 값을 초기화하여 같은 파일을 다시 선택할 수 있도록 함
        event.target.value = '';
    }, [addNewFiles]);

    const handleDelete = useCallback((imageId) => {
        const imageToDelete = images.find(img => img.id === imageId);
        if (imageToDelete && imageToDelete.preview && imageToDelete.preview.startsWith('blob:')) {
            URL.revokeObjectURL(imageToDelete.preview);
        }
        
        const newImages = images.filter(img => img.id !== imageId);
        updateImages(newImages);
    }, [images, updateImages]);

    const onDragEnd = useCallback((result) => {
        if (!result.destination) return;

        const items = Array.from(images);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        updateImages(items);
    }, [images, updateImages]);

    // 이미지 클릭 핸들러 추가
    const handleImageClick = useCallback((imageUrl) => {
        setSelectedImage(imageUrl);
        setIsModalOpen(true);
    }, []);

    // 모달 닫기 핸들러 추가
    const handleCloseModal = useCallback(() => {
        setIsModalOpen(false);
        setSelectedImage(null);
    }, []);

    // 드래그 앤 드롭 이벤트 핸들러 메모이제이션
    const handleDragOver = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const handleDragEnter = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    }, []);

    const handleDragLeave = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!e.currentTarget.contains(e.relatedTarget)) {
            setIsDragging(false);
        }
    }, []);

    const handleDrop = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        
        const files = Array.from(e.dataTransfer.files);
        addNewFiles(files);
    }, [addNewFiles]);

    // 이미지 플레이스홀더 생성 함수
    const renderPlaceholders = () => {
        const placeholders = [];
        const totalPlaceholders = 10;
        const filledPlaceholders = images.length;
        const emptyPlaceholders = Math.max(0, totalPlaceholders - filledPlaceholders);
        
        for (let i = 0; i < emptyPlaceholders; i++) {
            placeholders.push(
                <div key={`placeholder-${i}`} className="ImageUpload-placeholder">
                    <div className="ImageUpload-placeholderContent">
                        <span className="ImageUpload-placeholderIcon"></span>
                    </div>
                </div>
            );
        }
        
        return placeholders;
    };

    return (
        <div className="ImageUpload-container">
            <h3 className="ImageUpload-title">사진</h3>
            
            <div 
                className={`ImageUpload-dropzone ${isDragging ? 'ImageUpload-dropzone--dragging' : ''}`}
                onDragEnter={handleDragEnter}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                <label htmlFor="image-upload" className="ImageUpload-label">
                    <div className="ImageUpload-plusIcon">+</div>
                    <div className="ImageUpload-text">파일을 드래그하여 업로드 할 수 있습니다.</div>
                    <input
                        type="file"
                        id="image-upload"
                        multiple
                        accept="image/*"
                        onChange={handleFileSelect}
                        className="ImageUpload-fileInput"
                    />
                </label>
                {isDragging && (
                    <div className="ImageUpload-dragOverlay">
                    </div>
                )}
                <div className="ImageUpload-countText">최대 10장까지 업로드 가능합니다.</div>
            </div>
            
            
            
            <div className="ImageUpload-previewContainer">
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="images" direction="horizontal">
                        {(provided) => (
                            <div className="ImageUpload-grid" {...provided.droppableProps} ref={provided.innerRef}>
                                {images.map((image, index) => (
                                    <Draggable key={image.id} draggableId={image.id} index={index}>
                                        {(provided) => (
                                            <div 
                                                className="ImageUpload-previewItem"
                                                ref={provided.innerRef} 
                                                {...provided.draggableProps} 
                                                {...provided.dragHandleProps}
                                            >
                                                <div className="ImageUpload-previewWrapper">
                                                    <img 
                                                        src={image.preview} 
                                                        alt="preview" 
                                                        className="ImageUpload-previewImage"
                                                        // onClick 핸들러 추가
                                                        onClick={() => handleImageClick(image.preview)} 
                                                        style={{ cursor: 'pointer' }} // 클릭 가능 표시
                                                        onError={(e) => {
                                                            e.target.onerror = null;
                                                            e.target.src = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIwIiBoZWlnaHQ9IjEyMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cmVjdCB3aWR0aD0iMTIwIiBoZWlnaHQ9IjEyMCIgZmlsbD0iI2VlZSIvPjx0ZXh0IHg9IjYwIiB5PSI2MCIgZm9udC1mYW1pbHk9IkFyaWFsIiBmb250LXNpemU9IjEyIiBmaWxsPSIjOTk5IiB0ZXh0LWFuY2hvcj0ibWlkZGxlIiBhbGlnbm1lbnQtYmFzZWxpbmU9Im1pZGRsZSI+7J2066+47KeAIOyXhuyVnOyXkOufrOyYpOq4sCDsi6TrpqztlZjsl6wg7JWI64+Z64qUIOydtOuvuOyngDwvdGV4dD48L3N2Zz4=';
                                                        }}
                                                    />
                                                    <button 
                                                        onClick={() => handleDelete(image.id)} 
                                                        className="ImageUpload-deleteButton"
                                                        type="button"
                                                    >
                                                        &times;
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                                {renderPlaceholders() /* 플레이스홀더 렌더링 */}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>

            {/* 모달 추가 */}
            {isModalOpen && (
                <div className="ImageUpload-modal" onClick={handleCloseModal}>
                    <div className="ImageUpload-modalContent" onClick={(e) => e.stopPropagation()}>
                        <span className="ImageUpload-modalClose" onClick={handleCloseModal}>&times;</span>
                        {selectedImage && <img src={selectedImage} alt="Selected Preview" className="ImageUpload-modalImage" />}
                    </div>
                </div>
            )}
        </div>
    );
};

ImageUpload.propTypes = {
    onImagesChange: PropTypes.func.isRequired
};

export default ImageUpload;
