import React from 'react';
import {Helmet} from 'react-helmet';
import {Link, useParams} from 'react-router-dom';
import {gql} from 'apollo-boost';
import PropTypes from 'prop-types';
import {useQuery} from '@apollo/react-hooks';

import Error from '../../component/error/Error';
import Loading from '../../component/loading/Loading';
import Like from '../seller/like/Like';
import {SELLER_TAB} from '../seller/Seller';
import SellerOpenGraph from '../seller/SellerOpenGraph';
import SellerTwitterCard from '../seller/SellerTwitterCard';
import Shop from '../seller/shop/Shop';
import Review from '../seller/review/Review';
import Profile from '../seller/profile/Profile';
import {SCHEMA_CONTEXT, sellerSchemaOrg} from '../../util/schemaOrg';
import {seoURIComponent} from '../../util/text';
import {myPageUrl, webappURL} from '../../util/url';

// GraphQL queries
const SEE_ME = gql`
    query getMyAccountInfos {
        seeMe {
            id
            login_type
            username
            nickname
            access_token
            nickname
            following_count
            follower_count
            bio
            resizedSmallImage
            resizedBigImage
            email
            phone
            gender
            createdAt
            updatedAt
            seller {
                id
                income
                rating
                sold_count
                fee
                is_certified
                seller_account {
                    bank_name
                    account_holder_name
                    bank_account
                }
            }
        }
    }
`;

/**
 * MyPage
 */
export default function MyPage({hostname, userAgent}) {
    const {
        sellerTab: encodedSellerTab = seoURIComponent(SELLER_TAB.SHOP.description)
    } = useParams();

    const sellerTab = Object.values(SELLER_TAB)
        .find(symbol => encodedSellerTab === seoURIComponent(symbol.description));

    // fetch data
    const {loading, error, data} = useQuery(
        SEE_ME, {
            fetchPolicy: 'network-only'
        }
    );

    if (loading) {
        return <Loading />;
    } else if (error) {
        return <Error error={error} />;
    }

    const seller = {
        ...data.seeMe,
        reviews: new Array(data.seeMe.seller.review_count),
        rating: data.seeMe.seller.rating,
        is_certified: data.seeMe.seller.is_certified,
        user: Object.fromEntries(
            Object.entries(data.seeMe)
                .filter(([key]) => key !== 'seller')
        )
    };

    // build the UI elements
    const tabContent = (() => {
        switch (sellerTab) {
            case SELLER_TAB.SHOP:
                return <Shop seller={seller}/>;
            case SELLER_TAB.LIKE:
                return <Like seller={seller}/>;
            case SELLER_TAB.REVIEW:
                return <Review seller={seller}/>;
            default:
                throw new Error('Unknown seller tab type.');
        }
    })();

    function getSellerTabToKorean(sellerTab) {
        switch (sellerTab) {
            case SELLER_TAB.SHOP:
                return {
                    title :'MY',
                    description: `{${seller.user.bio}}`
                }
            case SELLER_TAB.LIKE:
                return {
                    title :'위시리스트 | MY',
                    description: '후루츠패밀리에서 찜한 MY 위시리스트입니다.'
                }
            case SELLER_TAB.REVIEW:
                return {
                    title :'리뷰 | MY',
                    description: ' 후루츠패밀리 MY 상점에 남겨진 리뷰입니다.'
                }
            default:
                throw new Error('Unknown seller tab type.');
        }
    }

    // meta tag
    const metaTitle = `${getSellerTabToKorean(sellerTab).title} | 후루츠패밀리`;
    const metaDescription = getSellerTabToKorean(sellerTab).description

    return (
        <section className="Seller container">
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription} />
                <link rel="canonical" href={webappURL(hostname) + myPageUrl(sellerTab)} />
                <script type="application/ld+json">{JSON.stringify(sellerSchemaOrg(seller, SCHEMA_CONTEXT))}</script>
            </Helmet>
            <SellerOpenGraph seller={seller} sellerTab={sellerTab} title={metaTitle} description={metaDescription} />
            <SellerTwitterCard seller={seller} sellerTab={sellerTab} title={metaTitle} description={metaDescription} />

            <div className="Seller-details">
                <Profile hostname={hostname}
                         seller={seller}
                         userAgent={userAgent}
                         isMe={true}
                         sellerTab={sellerTab}/>
            </div>
            <nav className="Seller-nav sticky-top">
                <ul className="Seller-nav-underline nav nav-underline">
                    <Link className={`Seller-nav-link nav-item nav-link ${sellerTab === SELLER_TAB.SHOP ? 'active' : ''}`}
                          to={myPageUrl(SELLER_TAB.SHOP)}
                          replace={true}>
                        My Shop
                    </Link>
                    <Link className={`Seller-nav-link nav-item nav-link ${sellerTab === SELLER_TAB.LIKE ? 'active' : ''}`}
                          to={myPageUrl(SELLER_TAB.LIKE)}
                          replace={true}>
                        Wishlist
                    </Link>
                    <Link className={`Seller-nav-link nav-item nav-link ${sellerTab === SELLER_TAB.REVIEW ? 'active' : ''}`}
                          to={myPageUrl(SELLER_TAB.REVIEW)}
                          replace={true}>
                        Reviews
                    </Link>
                    <div className="nav-slider"></div>
                </ul>
            </nav>
            {tabContent}
        </section>
    );
}
MyPage.propTypes = {
    hostname: PropTypes.string.isRequired,
    userAgent: PropTypes.string.isRequired
};
