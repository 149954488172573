import React, { useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';

import Rating from '../../../component/rating/Rating';
import Share from '../../../component/share/Share';
import UserPreview from '../../../component/userPreview/UserPreview';
import SellerCertificationPopup from '../../../component/popup/SellerCertificationPopup';
import appLink from '../../../util/appLink';
import {numberCompactFormat} from '../../../util/locale';
import {sellerUrl, uploadUrl} from '../../../util/url';

import './Profile.css';

/**
 * Seller profile component.
 */
export default function Profile({ hostname, seller, userAgent, isMe = false, sellerTab }) {
    const navigate = useNavigate();
    const applinkUrl = appLink(hostname, sellerUrl(seller), userAgent);
    const [showCertificationPopup, setShowCertificationPopup] = useState(false);

    // 모범셀러 여부 확인
    const isCertifiedSeller = seller && seller.is_certified === true;
    // 업로드 버튼 클릭 핸들러
    const handleUploadClick = (e) => {
        e.preventDefault();
        
        if (isCertifiedSeller) {
            // 모범셀러인 경우 업로드 페이지로 이동
            navigate(uploadUrl());
        } else {
            // 모범셀러가 아닌 경우 팝업 표시
            setShowCertificationPopup(true);
        }
    };

    // build the UI elements
    return (
        <div className="Profile">
            <div className="Profile-image float-left">
                <UserPreview user={{...seller.user, resizedSmallImage: seller.user.resizedBigImage}} />
            </div>

            <div className="Profile-share">
                <Share hostname={hostname}
                       path={sellerUrl(seller, sellerTab)}
                       text={`${seller.user.nickname} (@${seller.user.username}) on FruitsFamily`} />
            </div>

            {/* hidden h1 */}
            <h1 className="Seller-h1-tag">{`${seller.user.nickname} | 빈티지 세컨핸드 패션 플랫폼`}</h1>

            <div className="Profile-nickname font-nhaasgrotesk">
                {seller.user.nickname}
            </div>
            <h2 className="Profile-username">
                {`@${seller.user.username}`}
            </h2>

            <div className="Profile-rating">
                <Rating rating={seller.rating} />
            </div>
            {isMe &&
                <div className="Profile-follow-count">
                    <span className="mr-3"><span className="font-weight-bold">{numberCompactFormat(seller.user.follower_count)}</span> 팔로워</span>
                    <span><span className="font-weight-bold">{numberCompactFormat(seller.user.following_count)}</span> 팔로우</span>
                </div>
            }

            <div className="clearfix"></div>


            <div className="Profile-actions d-flex">
                {isMe ? <>
                        <Link className="Profile-myBtn btn btn-outline-dark mr-2"
                           to='/my/purchased'>
                            구매내역
                        </Link>
                        <Link className="Profile-myBtn btn btn-outline-dark mr-2"
                              onClick={handleUploadClick}>
                            업로드
                        </Link>
                        <Link className="Profile-myBtn btn btn-dark"
                              to='/my/settings'>
                            설정
                        </Link>
                    </> :
                    <>
                        <div className="Profile-follower btn btn-outline-dark flex-fill mr-2">
                            {numberCompactFormat(seller.user.follower_count)} 팔로워
                        </div>
                        <div className="Profile-following btn btn-outline-dark flex-fill mr-2">
                            {numberCompactFormat(seller.user.following_count)} 팔로우
                        </div>
                        <a className="Profile-follow btn btn-dark flex-fill"
                           href={applinkUrl}>
                            팔로우
                        </a>
                    </>
                }
            </div>

            {seller.user.bio && (
                <p className="Profile-bio">
                    {seller.user.bio}
                </p>
            )}
            {showCertificationPopup && (
                <SellerCertificationPopup onClose={() => setShowCertificationPopup(false)} />
            )}
        </div>
    );
}
Profile.propTypes = {
    hostname: PropTypes.string.isRequired,
    seller: PropTypes.shape({
        id:  PropTypes.string.isRequired,
        rating: PropTypes.number,
        is_certified: PropTypes.bool,
        user: PropTypes.shape({
            username: PropTypes.string.isRequired,
            nickname: PropTypes.string.isRequired,
            bio: PropTypes.string,
            resizedBigImage: PropTypes.string,
            follower_count: PropTypes.number.isRequired,
            following_count: PropTypes.number.isRequired
        })
    }).isRequired,
    userAgent: PropTypes.string.isRequired,
    isMe: PropTypes.bool,
    sellerTab: PropTypes.symbol
};
