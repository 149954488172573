import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import './ProductDetailInfo.css';
import {gql} from "apollo-boost";
import {useQuery, useLazyQuery} from "@apollo/react-hooks";
import {PRODUCT_GENDER} from "../Upload";
import ProductBrandInfo from './ProductBrandInfo';

const GET_CATEGORIES = gql`
    query getCategoriesCached($genders: [Gender!]) {
        getCategoriesCached(genders: $genders, limit: 1000) {
            name
            subcategories {
                id
                name
            }
        }
    }
`;

const GET_SIZE_VALUES = gql`
    query getSizeValues($name: String!) {
        getSizeValues(name: $name) {
            id
            name
        }
    }
`;

const ProductDetailInfo = ({ register, watch, setValue }) => {
    // 폼 데이터에서 값 가져오기
    const genderFromForm = watch('gender') || '';
    const categoryFromForm = watch('category') || '';
    const subCategoryFromForm = watch('subCategory') || '';

    // 상태 관리
    const [selectedGender, setSelectedGender] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSubCategory, setSelectedSubCategory] = useState('');
    const [sizeValues, setSizeValues] = useState([]);
    
    // GraphQL로 카테고리 데이터 가져오기
    const [getCategories, { data, loading, error }] = useLazyQuery(GET_CATEGORIES);

    // 성별이 변경될 때마다 카테고리 데이터 다시 가져오기
    useEffect(() => {
        getCategories({
            variables: {
                genders: selectedGender ? [selectedGender === '남성' ? PRODUCT_GENDER.MEN : PRODUCT_GENDER.WOMEN, PRODUCT_GENDER.UNISEX] : [PRODUCT_GENDER.UNISEX]
            }
        });
    }, [selectedGender]);

    // 카테고리 데이터 처리
    const allCategories = useMemo(() => {
        if (!data || !data.getCategoriesCached) return [];
        return data.getCategoriesCached;
    }, [data, selectedGender]);

    const allSubCategories = useMemo(() => {
        if (!allCategories || allCategories.length === 0) return [];
        return allCategories.flatMap(category => category.subcategories);
    }, [allCategories]);

    const allSubCategoryNames = useMemo(() => {
        if (!allSubCategories || allSubCategories.length === 0) return [];
        return allSubCategories.map(sub => sub.name);
    }, [allSubCategories]);
    
    const { data: sizeData, loading: sizeLoading } = useQuery(GET_SIZE_VALUES, {
        variables: { name: selectedSubCategory || (allSubCategoryNames.length > 0 ? allSubCategoryNames[0] : '') },
        skip: !selectedSubCategory && allSubCategoryNames.length === 0,
    });
    
    // 사이즈 데이터 로드
    useEffect(() => {
        if (sizeData && sizeData.getSizeValues) {
            setSizeValues(sizeData.getSizeValues);
        }
    }, [sizeData]);
    
    // 성별 선택 핸들러
    const handleGenderChange = (e) => {
        setSelectedGender(e.target.value);
        setValue('gender', e.target.value);
    };
    
    // 카테고리 선택 핸들러
    const handleCategoryChange = (e) => {
        setSelectedCategory(e.target.value);
        setValue('category', e.target.value);
    };
    
    // 서브카테고리 선택 핸들러
    const handleSubCategoryChange = (e) => {
        setSelectedSubCategory(e.target.value);
        setValue('subCategory', e.target.value);
    };

    useEffect(() => {
        if (genderFromForm && genderFromForm !== selectedGender) {
            setSelectedGender(genderFromForm);
        }
        
        if (categoryFromForm && categoryFromForm !== selectedCategory) {
            setSelectedCategory(categoryFromForm);
        }
        
        if (subCategoryFromForm && subCategoryFromForm !== selectedSubCategory) {
            setSelectedSubCategory(subCategoryFromForm);
        }
    }, [genderFromForm, categoryFromForm, subCategoryFromForm, selectedGender, selectedCategory, selectedSubCategory]);

    // 선택된 카테고리의 서브카테고리
    const selectedCategoryData = useMemo(() => {
        return allCategories.find(cat => cat.name === selectedCategory);
    }, [allCategories, selectedCategory]);

    return (
        <div className="ProductDetailInfo-container">
            <div className="ProductDetailInfo-formGroup">
                <label>성별</label>
                <div className="ProductDetailInfo-genderGrid">
                    {['남성', '여성'].map((gender) => (
                        <label key={gender} className="ProductDetailInfo-genderOption">
                            <input
                                type="radio"
                                value={gender}
                                checked={selectedGender === gender}
                                onChange={handleGenderChange}
                                {...register('gender', { required: '성별을 선택해주세요' })}
                            />
                            <span className="ProductDetailInfo-genderLabel">{gender}</span>
                        </label>
                    ))}
                </div>
            </div>

            <div className="ProductDetailInfo-formGroup">
                <label>카테고리</label>
                {loading ? (
                    <div className="ProductDetailInfo-loadingIndicator">
                        <div className="ProductDetailInfo-loadingSpinner"></div>
                    </div>
                ) : error ? (
                    <div className="ProductDetailInfo-error">
                        카테고리를 불러오는데 실패했습니다
                        <br />
                        {error.message}
                    </div>
                ) : allCategories.length > 0 && (
                    <div className="ProductDetailInfo-commonGrid">
                        {allCategories.map((category) => (
                            <label key={category.name} className="ProductDetailInfo-commonOption">
                                <input
                                    type="radio"
                                    value={category.name}
                                    checked={selectedCategory === category.name}
                                    onChange={handleCategoryChange}
                                    disabled={!selectedGender}
                                    {...register('category', { required: '카테고리를 선택해주세요' })}
                                />
                                <span className={`ProductDetailInfo-commonLabel ${!selectedGender ? 'ProductDetailInfo-commonLabel--disabled' : ''}`}>
                                    {category.name}
                                </span>
                            </label>
                        ))}
                    </div>
                )}
            </div>
            
            <div className="ProductDetailInfo-formGroup">
                <label>서브카테고리</label>
                {loading ? (
                    <div className="ProductDetailInfo-loadingIndicator">
                        <div className="ProductDetailInfo-loadingSpinner"></div>
                    </div>
                ) : error ? (
                    <div className="ProductDetailInfo-error">
                        서브카테고리를 불러오는데 실패했습니다
                    </div>
                ) : (
                    <div className="ProductDetailInfo-commonGrid">
                        {selectedCategoryData ? (
                            selectedCategoryData.subcategories.map((sub) => (
                                <label key={sub.id} className="ProductDetailInfo-commonOption">
                                    <input
                                        type="radio"
                                        value={sub.name}
                                        checked={selectedSubCategory === sub.name}
                                        onChange={handleSubCategoryChange}
                                        disabled={!selectedCategory}
                                        {...register('subCategory', { required: '서브카테고리를 선택해주세요' })}
                                    />
                                    <span className={`ProductDetailInfo-commonLabel ${!selectedCategory ? 'ProductDetailInfo-commonLabel--disabled' : ''}`}>
                                        {sub.name}
                                    </span>
                                </label>
                            ))
                        ) : (
                            allSubCategories.length > 0 && allSubCategories.slice(0, 10).map((sub) => (
                                <label key={sub.id} className="ProductDetailInfo-commonOption">
                                    <input
                                        type="radio"
                                        value={sub.name}
                                        checked={selectedSubCategory === sub.name}
                                        onChange={handleSubCategoryChange}
                                        disabled={!selectedCategory}
                                        {...register('subCategory', { required: '서브카테고리를 선택해주세요' })}
                                    />
                                    <span className={`ProductDetailInfo-commonLabel ${!selectedCategory ? 'ProductDetailInfo-commonLabel--disabled' : ''}`}>
                                        {sub.name}
                                    </span>
                                </label>
                            ))
                        )}
                    </div>
                )}
            </div>

            <div className="ProductDetailInfo-formGroup">
                <label>사이즈</label>
                {sizeLoading ? (
                    <div className="ProductDetailInfo-loadingIndicator">
                        <div className="ProductDetailInfo-loadingSpinner"></div>
                    </div>
                ) : sizeValues.length > 0 ? (
                    <div className="ProductDetailInfo-commonGrid">
                        {sizeValues.map((size) => (
                            <label key={size.id} className="ProductDetailInfo-commonOption">
                                <input
                                    type="radio"
                                    value={size.name}
                                    disabled={!selectedSubCategory}
                                    {...register('size', { required: '사이즈를 선택해주세요' })}
                                />
                                <span className={`ProductDetailInfo-commonLabel ${!selectedSubCategory ? 'ProductDetailInfo-commonLabel--disabled' : ''}`}>
                                    {size.name}
                                </span>
                            </label>
                        ))}
                    </div>
                ) : (
                    <div className="ProductDetailInfo-placeholderMessage">서브카테고리를 선택하면 사이즈 옵션이 표시됩니다</div>
                )}
            </div>

            <div className="ProductDetailInfo-formGroup">
                <label>상태</label>
                <div className="ProductDetailInfo-commonGrid">
                    {['새상품', '아주 좋은 상태', '약간의 사용감', '사용감 있음'].map((condition) => (
                        <label key={condition} className="ProductDetailInfo-commonOption">
                            <input
                                type="radio"
                                value={condition}
                                disabled={!selectedSubCategory}
                                {...register('condition', { required: '상태를 선택해주세요' })}
                            />
                            <span className={`ProductDetailInfo-commonLabel ${!selectedSubCategory ? 'ProductDetailInfo-commonLabel--disabled' : ''}`}>
                                {condition}
                            </span>
                        </label>
                    ))}
                </div>
            </div>

            <div className="ProductDetailInfo-formGroup">
                <ProductBrandInfo register={register} watch={watch} setValue={setValue} />
            </div>
        </div>
    );
};

ProductDetailInfo.propTypes = {
    register: PropTypes.func.isRequired,
    watch: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired
};

export default ProductDetailInfo;
