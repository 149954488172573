import React from "react";

import './UploadHeader.css';
import {useNavigate} from "react-router-dom";

/**
 * 업로드 화면 헤더
 * @returns {Element}
 * @constructor
 */
const UploadHeader = () => {
    const navigate = useNavigate();

    const handleOnCancel = () => {
        navigate(-1);
    }

    return <div className="UploadHeader-container">
        <div className="UploadHeader-header">
            <div className="UploadHeader-title-container">
                <h1 className="UploadHeader-title">상품 등록</h1>
                <div className="UploadHeader-label">Beta</div>
            </div>
            <div className="UploadHeader-buttons">
                <button className="UploadHeader-cancel-button"
                        onClick={handleOnCancel}>취소</button>
                <button
                    type="submit"
                    className='UploadHeader-upload-button'>
                    업로드
                </button>
            </div>
        </div>
    </div>
}

export default UploadHeader;