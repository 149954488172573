import {ApolloClient} from 'apollo-client';
import {createUploadLink} from 'apollo-upload-client';
import {InMemoryCache} from 'apollo-cache-inmemory';
import { setContext } from '@apollo/client/link/context';

import profile from './profile';
import {onError} from "@apollo/client/link/error";
import {gql} from "apollo-boost";
import {ApolloLink, Observable} from "@apollo/client";
import {useAuthStore} from "./store/useAuthStore";
import {getAccessKey, getRefreshKey, setAuthKey} from "./util/secureAuthStorage";

const QUERY_REFRESH_TOKEN = gql`
  query RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      access_token
      refresh_token
    }
  }
`;

async function refreshAccessToken(refreshToken) {
    const { data } = await apolloClient.mutate({
        mutation: QUERY_REFRESH_TOKEN,
        variables: { refreshToken },
    });

    return data;
}

const uploadLink = createUploadLink({
    uri: profile.apiURI(window.location.hostname),
});

const authLink = setContext((_, { headers }) => {
    const token = getAccessKey();

    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    }
});

const errorLink = onError(
    ({ graphQLErrors, operation, forward }) => {
        console.log(graphQLErrors);
        if (graphQLErrors) {
            for (let err of graphQLErrors) {
                console.log(err);
                switch (err.statusCode) {
                    case 403:
                        if (operation.operationName === 'refreshToken') return;

                        // eslint-disable-next-line no-case-declarations
                        const observable = new Observable(
                            (observer) => {
                                (async () => {
                                    try {
                                        if (getRefreshKey() === null) {
                                            throw new Error('refresh_token is null');
                                        }

                                        const data = await refreshAccessToken(getRefreshKey());

                                        if (data) {
                                            setAuthKey(data.refreshToken.access_token, data.refreshToken.refresh_token);
                                        }
                                        const subscriber = {
                                            next: observer.next.bind(observer),
                                            error: observer.error.bind(observer),
                                            complete: observer.complete.bind(observer),
                                        };

                                        forward(operation).subscribe(subscriber);
                                    } catch (err) {
                                        observer.error(err);
                                        useAuthStore.getState().logout();
                                    }
                                })();
                            }
                        );
                        return observable;
                }
            }
        }
    }
);


// TODO switch back to apollo boost once the ssrForceFetchDelay option is supported
// cf https://github.com/apollographql/apollo-client/pull/5464
export const apolloClient = new ApolloClient({
    cache: new InMemoryCache({
        cacheRedirects: {
            Query: {
                // preload product details from the products list cache
                seeProduct: (_, args, { getCacheKey }) => {
                    return getCacheKey({__typename: 'ProductNotMine', id: String(args.id)});
                }
            },
        }
    }).restore(window.__APOLLO_STATE__),
    link: ApolloLink.from([errorLink, authLink, uploadLink]),
    ssrForceFetchDelay: 100
});
