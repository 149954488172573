import React from 'react';
import PropTypes from 'prop-types';
import './UserTypeSelector.css';

const UserTypeSelector = ({ register, watch }) => {
    const feeAssigned = watch('fee_assigned');
    
    return (
        <div className="user-type-selector">
            <div className="selector-header">
                <h3>거래 수수료 부담 대상</h3>
            </div>
            <div className="selector-options">
                <label className="option-card">
                    <input
                        type="radio"
                        value="SELLER"
                        {...register('fee_assigned', { required: true })}
                        defaultChecked={feeAssigned === 'SELLER'}
                    />
                    <div className="card-content">
                        <span className="lightning-icon">⚡</span>
                        <span className="option-text">판매자(나)</span>
                    </div>
                </label>
                <label className="option-card">
                    <input
                        type="radio"
                        value="BUYER"
                        {...register('fee_assigned', { required: true })}
                        defaultChecked={feeAssigned === 'BUYER'}
                    />
                    <div className="card-content">
                        <span className="option-text">구매자</span>
                    </div>
                </label>
            </div>
            <p className="beta-notice">판매자가 수수료 부담 대상을 자유롭게 선택할 수 있어요.</p>
        </div>
    );
};

UserTypeSelector.propTypes = {
    register: PropTypes.func.isRequired,
    watch: PropTypes.func.isRequired
};

export default UserTypeSelector;
