import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { gql } from "apollo-boost";
import ImageUpload from './components/ImageUpload';
import ProductBasicInfo from './components/ProductBasicInfo';
import ProductPriceInfo from './components/ProductPriceInfo';
import ProductDetailInfo from './components/ProductDetailInfo';
import UserTypeSelector from './components/UserTypeSelector';
import UploadHeader from "./components/UploadHeader";
import UploadFooter from "./components/UploadFooter";
import SellerCertificationPopup from '../../component/popup/SellerCertificationPopup';
import MountedWrapper from '../../component/mountedWrapper/MountedWrapper';
import { useAuthStore } from '../../store/useAuthStore';
import {loginUrl, myPageUrl, productUrl} from '../../util/url';
import './Upload.css';

// Gender 상수 정의
export const PRODUCT_GENDER = {
    UNISEX: "UNISEX",
    MEN: "MEN",
    WOMEN: "WOMEN"
};

// Condition 상수 정의
export const PRODUCT_CONDITION = {
    NEW: 'new',
    GOOD_CONDITION: 'good_condition',
    LIGHTLY_WORN: 'lightly_worn',
    WORN: 'worn',
};

// gender 값 변환 함수
const convertGender = (gender) => {
    const genderMap = {
        '남성': PRODUCT_GENDER.MEN,
        '여성': PRODUCT_GENDER.WOMEN,
        '공용': PRODUCT_GENDER.UNISEX
    };
    return genderMap[gender] || PRODUCT_GENDER.UNISEX;
};

// condition 값 변환 함수
const convertCondition = (condition) => {
    const conditionMap = {
        '새상품': PRODUCT_CONDITION.NEW,
        '거의새것': PRODUCT_CONDITION.GOOD_CONDITION,
        '약간사용감': PRODUCT_CONDITION.LIGHTLY_WORN,
        '사용감있음': PRODUCT_CONDITION.WORN
    };
    return conditionMap[condition] || PRODUCT_CONDITION.NEW;
};

const POST_UPLOAD_PRODUCT = gql`
  mutation uploadProduct(
    $files: [Upload!]!
    $gender: Gender!
    $category: String!
    $subcategory: String
    $title: String!
    $description: String
    $brand: String
    $size: String
    $condition: Condition
    $price: Int!
    $shipping_fee: Int!
    $shipping_fee_island: Int!
    $is_negotiation: Boolean
    $fee_assigned: String
  ) {
    uploadProduct(
      files: $files
      gender: $gender
      category: $category
      sub_category: $subcategory
      title: $title
      description: $description
      brand: $brand
      size: $size
      condition: $condition
      price: $price
      shipping_fee: $shipping_fee
      shipping_fee_island: $shipping_fee_island
      is_negotiation: $is_negotiation
      fee_assigned: $fee_assigned
    ) {
      id
    }
  }
`;

const Upload = () => {
    const { isLogin, user } = useAuthStore();
    const [productImages, setProductImages] = useState([]);
    const [showCertificationPopup, setShowCertificationPopup] = useState(false);
    const { register, handleSubmit, formState: { errors }, watch, setValue, trigger } = useForm({
        defaultValues: {
            isNegotiable: true,
            fee_assigned: 'SELLER'
        }
    });

    const [uploadProduct] = useMutation(POST_UPLOAD_PRODUCT);

    // 로그인 체크
    useEffect(() => {
        const timer = setTimeout(() => {
            if (!isLogin) {
                alert('로그인 후 이용가능합니다.');
                window.location.href = loginUrl();
                return;
            }
            
            // 모범셀러 체크
            if (user && user.seller && user.seller.is_certified === false) {
                setShowCertificationPopup(true);
            }
        }, 1000);
        return () => clearTimeout(timer);
    }, [isLogin, user]);

    // 페이지 전체 스크롤 방지
    useEffect(() => {
        // 이전 overflow 스타일 저장
        const originalStyle = document.body.style.overflow;
        
        // body의 overflow를 hidden으로 설정하여 스크롤 방지
        document.body.style.overflow = 'hidden';
        
        // 컴포넌트 언마운트 시 원래 스타일로 복원
        return () => {
            document.body.style.overflow = originalStyle;
        };
    }, []);

    // 팝업 닫기 핸들러
    const handleClosePopup = () => {
        setShowCertificationPopup(false);
        window.location.href = myPageUrl();
    };

    const onSubmit = async (data) => {
        if (!productImages || productImages.length === 0) {
            alert('최소 1개 이상의 상품 이미지를 등록해주세요.');
            return;
        }

        try {
            const variables = {
                files: productImages,
                gender: convertGender(data.gender).toUpperCase(),
                category: data.category,
                subcategory: data.subCategory,
                title: data.title,
                description: data.description,
                brand: data.brand,
                size: data.size,
                condition: convertCondition(data.condition).toUpperCase(),
                price: parseInt(data.price),
                shipping_fee: parseInt(data.shipping_fee) || 0,
                shipping_fee_island: parseInt(data.shipping_fee_island) || 0,
                is_negotiation: data.isNegotiable,
                fee_assigned: data.fee_assigned,
            };
            const { data: uploadData } = await uploadProduct({
                variables,
            });

            window.location.href = productUrl(uploadData.uploadProduct);
        } catch (error) {
            alert('상품 등록 중 오류가 발생했습니다.');
        }
    };

    const onError = (errors) => {
        console.log('Form validation errors:', errors); // 폼 유효성 검사 에러 로그
    };

    const handleImagesChange = (images) => {
        setProductImages(images);
    };

    return (
        <MountedWrapper>
            <div className="upload-container">
                <form onSubmit={handleSubmit(onSubmit, onError)} className="upload-form">
                    <div className="upload-header-wrapper">
                        <UploadHeader />
                    </div>
                    
                    <div className="upload-content-container">
                        <div className="upload-left-column">
                            <div className="form-group">
                                <ImageUpload register={register} onImagesChange={handleImagesChange}/>
                            </div>
                        </div>
                        
                        <div className="upload-right-column">
                            <ProductBasicInfo
                                register={register}
                                watch={watch}
                                errors={errors}
                                trigger={trigger}
                                setValue={setValue}
                            />

                            <ProductDetailInfo
                                register={register}
                                watch={watch}
                                setValue={setValue}
                            />

                            <ProductPriceInfo
                                register={register}
                                errors={errors}
                            />

                            <UserTypeSelector
                                register={register}
                                watch={watch}
                            />
                        </div>
                    </div>
                    <div className="upload-footer-wrapper">
                    <UploadFooter register={register} watch={watch} />
                    </div>
                </form>
                
                {showCertificationPopup && (
                    <SellerCertificationPopup onClose={handleClosePopup} />
                )}
            </div>
        </MountedWrapper>
    );
};

export default Upload;
