import React from 'react';
import PropTypes from 'prop-types';
import './UploadFooter.css';

/**
 * 업로드 화면 푸터
 * 네고 가능 여부와 예상 정산금액을 표시하고 하단에 고정됨
 */
const UploadFooter = ({ register, watch }) => {
    const currentPrice = watch('price') || 0;
    const shippingFee = watch('shipping_fee') || 0;
    
    // 숫자만 추출하는 함수
    const convertOnlyNumber = (value) => {
        if (!value) return 0;
        return parseInt(value.toString().replace(/[^0-9]/g, ''), 10) || 0;
    };
    
    const calculateEstimatedAmount = () => {
        const CARD_FEE = 0.0385;
        return Math.floor(
            (convertOnlyNumber(currentPrice) + convertOnlyNumber(shippingFee)) *
            (1 - CARD_FEE)
        );
    };
    
    const formatPrice = (price) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    return (
        <div className="UploadFooter-container">
                <div className="UploadFooter-negotiation">
                    <div className="text-container">
                        <span className="title">네고 제안받기</span>
                        <span className="description">더 빠르게 판매하세요!</span>
                    </div>
                    <div className="checkbox-container">
                        <label className="toggle">
                            <input
                                type="checkbox"
                                {...register('isNegotiable')}
                            />
                            <span className="toggle-slider"></span>
                        </label>
                    </div>
                </div>
                
                <div className="UploadFooter-estimated">
                    <div className="label">예상 정산 금액</div>
                    <div className="amount">
                        {formatPrice(calculateEstimatedAmount())}
                        <span>원</span>
                    </div>
                </div>
            </div>
    );
};

UploadFooter.propTypes = {
    register: PropTypes.func.isRequired,
    watch: PropTypes.func.isRequired,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default UploadFooter;
