import React from "react";
import PropTypes from "prop-types";
import "./ProductPriceInfo.css";

/**
 * 가격 관련 정보
 */
const ProductPriceInfo = ({ register, errors }) => {
  return (
    <>
      <div className="ProductPriceInfo-shippingGroup">
          <div className="ProductPriceInfo-shippingFeeRow">
            <span className="ProductPriceInfo-shippingFeeLabel">
              기본 배송비
            </span>
            <div className="ProductPriceInfo-shippingFeeInput">
              <input
                type="number"
                className="ProductPriceInfo-formInput"
                placeholder="0"
                min="0"
                max="100000"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  if (parseInt(e.target.value) > 100000) {
                    e.target.value = "100000";
                  }
                }}
                {...register("shipping_fee", {
                  required: "기본 배송비를 입력해주세요",
                  min: {
                    value: 0,
                    message: "0원 이상 입력해주세요",
                  },
                  max: {
                    value: 100000,
                    message: "최대 100,000원까지 입력 가능합니다",
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "숫자만 입력 가능합니다",
                  },
                })}
              />
              <span className="ProductPriceInfo-priceUnit">원</span>
            </div>
          </div>
          <div className="ProductPriceInfo-shippingFeeRow">
            <span className="ProductPriceInfo-shippingFeeLabel">
              도서산간 배송비
            </span>
            <div className="ProductPriceInfo-shippingFeeInput">
              <input
                type="number"
                className="ProductPriceInfo-formInput"
                placeholder="0"
                min="0"
                max="100000"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  if (parseInt(e.target.value) > 100000) {
                    e.target.value = "100000";
                  }
                }}
                {...register("shipping_fee_island", {
                  required: "도서산간 배송비를 입력해주세요",
                  min: {
                    value: 0,
                    message: "0원 이상 입력해주세요",
                  },
                  max: {
                    value: 100000,
                    message: "최대 100,000원까지 입력 가능합니다",
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "숫자만 입력 가능합니다",
                  },
                })}
              />
              <span className="ProductPriceInfo-priceUnit">원</span>
            </div>
          </div>
        {errors.shipping_fee && (
          <p className="ProductPriceInfo-errorMessage">
            {errors.shipping_fee.message}
          </p>
        )}
        {errors.shipping_fee_island && (
          <p className="ProductPriceInfo-errorMessage">
            {errors.shipping_fee_island.message}
          </p>
        )}
      </div>
      <div className="ProductPriceInfo-priceGroup">
        <div className="ProductPriceInfo-inputContainer">
          <div className="ProductPriceInfo-inputHeader">
            <label htmlFor="price">판매가</label>
          </div>
          <div className="ProductPriceInfo-priceInputWrapper">
            <input
              id="price"
              type="number"
              className="ProductPriceInfo-formInput"
              placeholder="0"
              min="0"
              max="9999999"
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
                if (parseInt(e.target.value) > 9999999) {
                  e.target.value = "9999999";
                }
              }}
              {...register("price", {
                required: "판매가를 입력해주세요",
                min: {
                  value: 0,
                  message: "0원 이상 입력해주세요",
                },
                max: {
                  value: 9999999,
                  message: "최대 9,999,999원까지 입력 가능합니다",
                },
                pattern: {
                  value: /^[0-9]+$/,
                  message: "숫자만 입력 가능합니다",
                },
              })}
            />
            <span className="ProductPriceInfo-priceUnit">원</span>
          </div>
          {errors.price && (
            <p className="ProductPriceInfo-errorMessage">
              {errors.price.message}
            </p>
          )}
        </div>
      </div>
    </>
  );
};

ProductPriceInfo.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default ProductPriceInfo;
