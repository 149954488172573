import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';
import './ProductBrandInfo.css';

const SEARCH_BRANDS = gql`
    query SearchBrands($query: String!, $offset: Int!, $limit: Int!) {
        searchBrands(query: $query, offset: $offset, limit: $limit) {
            id
            name
            name_kr
        }
    }
`;

const ProductBrandInfo = ({ register, watch, setValue }) => {
    const brandFromForm = watch('brand') || '';

    const [brandQuery, setBrandQuery] = useState('');
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [brandResults, setBrandResults] = useState([]);
    const [selectedBrandIndex, setSelectedBrandIndex] = useState(-1);

    const [searchBrands, { loading: brandsLoading }] = useLazyQuery(SEARCH_BRANDS, {
        onCompleted: (data) => {
            if (data && data.searchBrands) {
                setBrandResults(data.searchBrands);
            }
        }
    });

    // 브랜드 검색 핸들러
    const handleBrandSearch = (e) => {
        const query = e.target.value;
        setBrandQuery(query);
        setSelectedBrand(null); // 검색 시작 시 선택된 브랜드 초기화
        setValue('brand', ''); // 폼 값도 초기화

        if (query.length >= 1) { // 1글자 이상일 때 검색 시작
            searchBrands({ variables: { query, offset: 0, limit: 10 } });
            setSelectedBrandIndex(-1); // 검색어 변경 시 선택 초기화
        } else {
            setBrandResults([]); // 검색어 없으면 결과 초기화
        }
    };

    // 브랜드 선택 핸들러
    const handleSelectBrand = (brand, index) => {
        setSelectedBrand(brand);
        setBrandQuery(''); // 검색 필드 초기화
        setValue('brand', brand.name);  // 폼 데이터 업데이트
        setSelectedBrandIndex(index);
        setBrandResults([]); // 결과 목록 숨김
    };

    // 브랜드 제거 핸들러
    const handleRemoveBrand = () => {
        setSelectedBrand(null);
        setValue('brand', '');  // 폼 데이터 업데이트
        setBrandQuery(''); // 브랜드 검색 필드 초기화
        setSelectedBrandIndex(-1);
    };

    // 외부(ProductBasicInfo 등)에서 brand 값이 변경되었을 때 내부 상태 업데이트
    useEffect(() => {
        if (brandFromForm) {
            // brandFromForm 값과 현재 selectedBrand.name이 다를 때만 업데이트
            // 또는 selectedBrand가 없을 때 초기 설정
            if (!selectedBrand || selectedBrand.name !== brandFromForm) {
                // searchBrands를 통해 실제 Brand 객체를 찾아 설정할 수도 있지만,
                // 여기서는 간단히 이름만 객체로 만들어 설정합니다.
                // 만약 ID 등 추가 정보가 필요하다면, 초기 로드 시 brandFromForm으로 검색하는 로직 추가 필요.
                setSelectedBrand({ name: brandFromForm });
                setBrandQuery(''); // 검색창은 비움
            }
        } else {
            // 외부에서 brand 값이 비워졌다면 내부 상태도 초기화
            if (selectedBrand) {
                 setSelectedBrand(null);
                 setBrandQuery('');
                 setSelectedBrandIndex(-1);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brandFromForm]); // brandFromForm만 의존성으로 설정


    return (
        <div className="ProductBrandInfo-container">
            <label>브랜드</label>
            <div className="ProductBrandInfo-brandInputArea">
                {selectedBrand ? (
                    <div className="ProductBrandInfo-selectedBrandTag">
                        <span className="ProductBrandInfo-brandName">{selectedBrand.name}</span>
                        <button type="button" className="ProductBrandInfo-removeBrandButton" onClick={handleRemoveBrand}>
                            ×
                        </button>
                    </div>
                ) : (
                    <div className="ProductBrandInfo-searchContainer">
                        <input
                            type="text"
                            placeholder="브랜드 검색"
                            value={brandQuery}
                            onChange={handleBrandSearch}
                            className="ProductBrandInfo-searchInput"
                            // register를 직접 사용하지 않고, setValue로 관리
                        />
                    </div>
                )}
            </div>
            {brandQuery && !selectedBrand && brandResults.length > 0 && (
                <div className="ProductBrandInfo-brandSearchResults">
                    {brandResults.slice(0, 5).map((brand, index) => (
                        <div
                            key={brand.id}
                            className={`ProductBrandInfo-brandResultItem ${selectedBrandIndex === index ? 'ProductBrandInfo-brandResultItem--selected' : ''}`}
                            onClick={() => handleSelectBrand(brand, index)}
                            // onKeyDown 등 키보드 네비게이션 추가 가능
                        >
                            {brand.name}
                        </div>
                    ))}
                </div>
            )}
            {brandQuery && !selectedBrand && brandResults.length === 0 && !brandsLoading && (
                <div className="ProductBrandInfo-noResults">검색 결과가 없습니다</div>
            )}
            {brandQuery && !selectedBrand && brandsLoading && (
                <div className="ProductBrandInfo-loadingIndicator">
                    <div className="ProductBrandInfo-loadingSpinner"></div>
                    <span>검색 중...</span>
                </div>
            )}
            {/* Hidden input to store the final brand value for react-hook-form */}
            <input type="hidden" {...register('brand')} />
        </div>
    );
};

ProductBrandInfo.propTypes = {
    register: PropTypes.func.isRequired,
    watch: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
};

export default ProductBrandInfo;
